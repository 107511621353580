<template>
  <dialog-outline v-model="showDialog" max-width="60%">
    <template v-slot:icon-name>
      {{ dialogIcon }}
    </template>
    <template v-slot:default>
      {{ dialogTitle }}
    </template>

    <!-- Close Dialog icon -->
    <template v-slot:icon-cross>
      <base-tooltip-button @click="closeDialog">
        <template v-slot:icon-name>
          {{ iconCloseDialog }}
        </template>
        <template v-slot:toolTip>
          {{ closeDialogTooltip }}
        </template>
      </base-tooltip-button>
    </template>

    <!-- context -->
    <template v-slot:context>
      <project-task :project="project" :task="task"></project-task>
    </template>

    <!-- Cancel Action -->
    <template v-slot:actionCancel>
      <base-button @click.stop="closeDialog()">
        <template v-slot:default>
          {{ dialogCancelCommandLabel }}
        </template>
      </base-button>
    </template>
  </dialog-outline>
</template>

<script>
// mixins
import { dialogOutlineMixin } from "@/mixins/shared/base/dialog/dialogOutlineMixin";
import { iconTask } from "@/design/icon/iconConst";

export default {
  name: "ProjectTaskDataDialog",
  components: {
    ProjectTask: () => import("@/components/project/ProjectTask.vue")
  },
  mixins: [dialogOutlineMixin],
  props: {
    project: undefined,
    task: undefined
  },
  data() {
    return {
      taskIcon: iconTask
    };
  },
  computed: {
    /**
     * dialog Icon
     * @return {string} Icon name
     */
    dialogIcon() {
      return this.taskIcon;
    },

    /**
     * Dialog Title
     * @return {string} Formatted download Dialog Title
     */
    dialogTitle() {
      return this.task.name;
    }
  }
};
</script>
